import React, { useEffect, useState } from "react"
import { BlobProvider, PDFViewer } from "@react-pdf/renderer"
import { navigate } from "gatsby"

import Page from "../../plugins/gatsby-grapesjs-page-builder/src/templates/page"

import { ResultDocument } from "./helpers/renderPDF"
import {
  sessionStore,
  localStore,
} from "../../plugins/gatsby-grapesjs-page-builder/src/utils/customStorage"

const section2List = detailedAnswers => {
  if (!detailedAnswers) return []
  let sortedAnwsers = Object.keys(detailedAnswers)
    .filter(a => a.startsWith("2"))
    .sort((a, b) => a.localeCompare(b))
  console.log("SORTED ANSWERS", sortedAnwsers)

  return sortedAnwsers.map(key => detailedAnswers[key])
}

const getValue = val => {
  const int = parseInt(val)
  return isNaN(int) || int === -1 ? 0 : int
}

const getScore = values => {
  // console.log("VALUES", values)

  if (!values) return null

  let total = 0

  for (const key in values) {
    if (key.startsWith("2")) total += getValue(values[key])
  }

  total += getValue(values["3.1.enfant_different"])
  total += getValue(values["4.1.antecedent_familiaux"])
  total += getValue(values["4.2.drogues"])
  total += getValue(values["4.2.drogues_impact"])
  total += getValue(values["5.1.problematique_sante_mentale"])
  total += getValue(values["5.2.stresseurs"])
  total += getValue(values["5.2.stresseurs_traumatisme_cranien"])
  total += getValue(values["5.3.risques_perinataux"])
  total += getValue(values["5.3.risques_environnement"])

  if (total <= 27) return "vert"
  else if (total <= 54) return "jaune"

  return "rouge"
}

const alerte1 = values => {
  if (!values) return false

  return (
    getValue(values["4.1.antecedent_familiaux"]) ||
    getValue(values["4.2.drogues"])
  )
}

const alerte2 = values => {
  if (!values) return false

  for (const key in values) {
    if (key.startsWith("2.5") && getValue(values[key]) > 1) return true
  }

  return false
}

const PageTemplate = props => {
  console.log("Recommandations", props)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://html2canvas.hertzen.com/dist/html2canvas.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const {
    location: { pathname },
  } = props
  const params = pathname.split("/")

  let pageData = {
    ...props.pageContext,
    type: params[3],
    functions: {
      section2List,
      getScore,
      alerte1,
      alerte2,
    },
  }

  if (typeof window === "undefined")
    return (
      <Page {...props} pageContext={{ ...props.pageContext, ...pageData }} />
    )

  const pdfData = sessionStore.getItem("pb-forms")
    ? JSON.parse(sessionStore.getItem("pb-forms"))
    : {}

  if (
    typeof window !== "undefined" &&
    !props.canvas &&
    (!pdfData.questionnaire ||
      !pdfData.questionnaire.values ||
      !pdfData.questionnaire.values["0.0.terms"])
  ) {
    navigate(`/${params[1]}/questionnaire/${params[3]}/1`)
  }

  /* return <PDFViewer width={960} style={{ width: '100vw', height: '100vh' }}>
        <ResultDocument data={pdfData} getScore={getScore} />
    </PDFViewer> */

  console.log("LANG PDF", params[1])

  return (
    <BlobProvider
      document={
        <ResultDocument data={pdfData} lang={params[1]} getScore={getScore} />
      }
    >
      {({ blob, url, loading, error }) => {
        return (
          <Page
            {...props}
            pageContext={{
              ...props.pageContext,
              ...pageData,
              pdfLink: url,
              pdfLoading: loading,
            }}
          />
        )
      }}
    </BlobProvider>
  )
}

export default PageTemplate
