/* eslint-disable react/react-in-jsx-scope */
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Link,
  StyleSheet,
  Font,
} from "@react-pdf/renderer"

Font.register({
  family: "Lato",
  fonts: [
    {
      src: `/fonts/Lato-Regular.ttf`,
      fontWeight: "normal",
    },
    {
      src: `/fonts/Lato-Bold.ttf`,
      fontWeight: "bold",
    },
    {
      src: `/fonts/Lato-Light.ttf`,
      fontWeight: "light",
    },
    {
      src: `/fonts/Lato-Italic.ttf`,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: `/fonts/Lato-BoldItalic`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/fonts/Lato-LightItalic`,
      fontWeight: "light",
      fontStyle: "italic",
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    fontFamily: "Lato",
    fontSize: "12px",
  },
  paragraph: {
    margin: "5px 0 10px 0",
    fontWeight: "light",
  },
  paragraphBold: {
    fontFamily: "Lato",
    fontWeight: "bold",
    margin: "5px 0 10px 0",
  },
  logo: {
    height: 32,
    width: 200,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#f1f1f1",
    padding: "20px 30px",
  },
  headerSlogan: {
    textAlign: "right",
    color: "#f8bc64",
  },
  headerText: {
    textAlign: "right",
    marginTop: 5,
  },
  content: {
    padding: "20px 30px",
  },
  recommandation: {
    border: "2px solid red",
    padding: "10px 20px",
    borderRadius: 2,
    marginTop: 5,
    marginBottom: 10,
  },
  recommandationTitle: {
    fontSize: 16,
    color: "red",
    marginBottom: 5,
  },
  paragraphResourcesTitle: {
    fontSize: 16,
    margin: "5px 0 10px 0",
  },
  paragraphResources: {
    margin: "2px 0",
    fontWeight: "light",
    fontSize: 10,
  },
  resultLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    borderBottom: "1px solid #f8bc64",
    padding: "5px 0",
  },
  resultText: {
    fontSize: 9,
    width: 240,
  },
  resultCheckbox: {
    width: 60,
    textAlign: "center",
    fontSize: 9,
  },
  baseDePage: {
    fontSize: 8,
    position: "absolute",
    left: 30,
    bottom: 15,
    color: "#444",
  },
})

const BasDePage = ({ isFr }) =>
  isFr ? (
    <View fixed style={styles.baseDePage}>
      <Text>
        Les données fournies ne seront pas conservées. | Site réalisé par{" "}
        <Link src="https://lbacreations.com">LBA Créations</Link>
      </Text>
      <Text>
        © Copyright {new Date().getFullYear()}. Tous droits réservés. | Le
        refer-O-scope est une réalisation de la Société québécoise de la
        schizophrénie et des psychoses apparentées.
      </Text>
    </View>
  ) : (
    <View fixed style={styles.baseDePage}>
      <Text>
        The answers you provide while filling out the questionnaire are not
        saved on the server. | Website by{" "}
        <Link src="https://lbacreations.com">LBA Créations</Link>
      </Text>
      <Text>
        © Copyright {new Date().getFullYear()}. All rights reserved. | The
        refer-O-scope is a realization of La Société québécoise de la
        schizophrénie et des psychoses apparentées.
      </Text>
    </View>
  )

export const ResultDocument = props => {
  console.log("PDF DATA", props.data)

  if (!props || !props.data || !props.data.questionnaire) return null

  const {
    questionnaire: { detailed, values },
  } = props.data
  const isFr = props.lang === "fr"

  const score = props.getScore(values)

  console.log("SCORE", score)

  let recColor = "#45b74b"
  let recTitle = isFr ? "Peu de signes inquiétants" : "No cause for concern"
  let recText = isFr
    ? "D’après vos réponses, rien n’indique qu’il y ait des raisons de vous inquiéter. La personne visée par vos observations montre peu de signes qui pourraient indiquer le développement d’une psychose. Si vous le souhaitez, vous pouvez poursuivre vos observations et reprendre le questionnaire plus tard. Si vous demeurez inquiet(e) ou gardez un doute, n’hésitez pas à partager vos réponses avec un médecin, ou à consulter l’une ou l’autre des ressources disponibles."
    : "Based on your answers, nothing indicates a cause for concern. There is little to indicate that this person will develop psychosis. However, you may continue to observe the person and fill out the questionnaire again at a later date. If you still have doubts or remain concerned, do not hesitate to share your answers with a doctor or consult an available resource in your area."

  if (score === "jaune") {
    recColor = "#e5a21b"
    recTitle = isFr ? "Signes préoccupants" : "Some concerning signs"
    recText = isFr
      ? `D’après vos réponses, il paraît souhaitable que vous communiquiez les résultats obtenus à votre médecin, à un professionnel de la santé ou au médecin traitant la personne visée par vos observations. Sans pour autant présumer de la possibilité de développer une psychose chez celle-ci, il y aurait lieu d’entreprendre d’autres démarches visant à aller plus loin dans la recherche d’un diagnostic. Voir à cet égard notre rubrique sur les diverses ressources disponibles.`
      : "Based on your answers, we suggest that you talk to your doctor, a health care professional or the doctor of the at-risk person. Although it is not clear whether the person will develop psychosis, there are signs indicating that further steps should be taken to establish a diagnosis. See below for a list of available resources."
  } else if (score === "rouge") {
    recColor = "#d6133a"
    recTitle = isFr ? "Importance d'intervenir" : "Action required"
    recText = isFr
      ? `D’après vos réponses, il semble justifié d’entreprendre une démarche auprès de votre médecin ou du médecin traitant la personne visée par vos observations. Celui-ci pourra vous guider vers les ressources en santé mentale susceptibles d’établir un diagnostic. Si vous jugez que la personne représente un danger pour elle-même ou pour autrui, consultez les ressources professionnelles d’urgence dès maintenant.`
      : "Based on your answers, it is strongly recommended that you consult your doctor or the doctor of the at-risk person, who will refer you to mental health resources that can establish a diagnosis. If you believe that the person is a danger to him or herself or to others, consult professional emergency resources immediately."
  }

  return isFr ? (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.header}>
          <View style={{ width: 200 }}>
            <Image src="/images/logo_gris.png" style={styles.logo} />
          </View>
          <View style={{ width: 400 }}>
            <Text style={styles.headerSlogan}>
              L'outil pour observer et agir avant la psychose
            </Text>
            <Text style={styles.headerText}>
              {new Date().toLocaleDateString()}
            </Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text style={styles.paragraph}>
            Le refer-O-scope est un outil d'aide à l'observation des signes
            avant-coureurs de la psychose, destiné aux membres des familles et
            aux professionnels œuvrant dans les réseaux de l’éducation, de la
            santé et des services sociaux.
          </Text>
          <Text style={styles.paragraph}>
            Cet outil poursuit un objectif pédagogique et de prévention ne
            pouvant en aucun cas se substituer à une évaluation médicale
            incluant les conseils, le diagnostic ou le traitement par un
            professionnel de la santé.
          </Text>
          <Text style={styles.paragraph}>
            Les réponses fournies au questionnaire sont traitées par un
            algorithme qui émet une recommandation quant à l'opportunité de
            poursuivre des démarches auprès d'un professionnel de la santé.
          </Text>
          <Text style={styles.paragraph}>
            L'algorithme repose sur les connaissances cliniques de plusieurs
            experts ayant participé à la conception du refer-O-scope, sur une
            revue rigoureuse de la littérature scientifique et sur les résultats
            de groupes de discussion.
          </Text>
          <Text style={styles.paragraph}>
            Pour favoriser les échanges avec un professionnel de la santé,
            seules les observations les plus pertinentes ont été retenues.
          </Text>
          <Text style={styles.paragraph}>
            En savoir plus sur les troubles psychotiques : www.aqppep.com
          </Text>
          <Text style={styles.paragraphBold}>
            Je m'inquiète de la santé mentale d'un proche. Voici mes
            observations et la recommandation de suivi.
          </Text>

          <View style={{ ...styles.recommandation, borderColor: recColor }}>
            <Text style={{ ...styles.recommandationTitle, color: recColor }}>
              {recTitle}
            </Text>
            <Text>{recText}</Text>
          </View>

          <View>
            <Text style={styles.paragraphResourcesTitle}>
              Faire appel à des ressources pertinentes
            </Text>
            <Text style={styles.paragraphResources}>
              1. Un médecin ou un professionnel de la santé
            </Text>
            <Text style={styles.paragraphResources}>
              2. Le Centre de santé et de services sociaux (CSSS) de votre
              région ou le Centre local de services communautaires (CLSC) le
              plus près de chez vous
            </Text>
            <Text style={styles.paragraphResources}>
              3. Ligne INFO-SANTÉ : 811
            </Text>
            <Text style={styles.paragraphResources}>
              4. Votre organisme régional de soutien aux familles{" "}
              <Link src="https://www.avantdecraquer.com/">
                www.avantdecraquer.com/
              </Link>
            </Text>
            <Text style={styles.paragraphResources}>
              5. Le Service d’aide aux étudiants de votre établissement
            </Text>
            <Text style={styles.paragraphResources}>
              6. La Société québécoise de la schizophrénie et des psychoses
              apparentées
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tél. : 1 866 888-2323 (sans frais)
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tél. : 514 251-4125
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link src="mailto:info@schizophrenie.qc.ca">
                info@schizophrenie.qc.ca
              </Link>
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link src="https://www.schizophrenie.qc.ca/">
                www.schizophrenie.qc.ca/fr/
              </Link>
            </Text>
          </View>
        </View>

        <BasDePage isFr={isFr} />
      </Page>

      <Page size="LETTER" style={styles.page}>
        <View style={styles.content}>
          <Text>
            Votre lien avec la personne visée par vos observations :{" "}
            {values["1.1.personne_repondante"]}
          </Text>
          <Text>
            Personne visée par vos observations : {values["1.2.sexe"]}
          </Text>
          <Text>Âge actuel : {values["1.2.age"]}</Text>
        </View>

        <View style={{ padding: "0px 30px" }}>
          <View style={styles.resultLine}>
            <Text style={styles.resultText}></Text>
            <View style={styles.resultCheckbox}>
              <Text>Jamais</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Quelquefois</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Souvent</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Très souvent</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Ne sais pas</Text>
            </View>
          </View>
          {Object.keys(values)
            .filter(k => k.startsWith("2"))
            .map(key => {
              return (
                <View key={key} style={styles.resultLine}>
                  <Text style={styles.resultText}>{detailed[key].label}</Text>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "0" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "1" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "2" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "3" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {(detailed[key].value === "-1" ||
                      detailed[key].value === "4") && <Text>x</Text>}
                  </View>
                </View>
              )
            })}
        </View>

        <BasDePage />
      </Page>
    </Document>
  ) : (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.header}>
          <View style={{ width: 200 }}>
            <Image src="/images/logo_gris.png" style={styles.logo} />
          </View>
          <View style={{ width: 400 }}>
            <Text style={styles.headerSlogan}>
              The tool to observe and act before the psychosis
            </Text>
            <Text style={styles.headerText}>
              {new Date().toLocaleDateString()}
            </Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text style={styles.paragraph}>
            The refer-O-scope is an on-line screening tool of the early warning
            signs of psychosis that is intended for adults from the general
            public and family members, as well as education professionals or
            other types of staff who work with at-risk clienteles.
          </Text>
          <Text style={styles.paragraph}>
            This tool is meant for educational and prevention purposes and may
            in no case be substituted for a medical evaluation, which includes
            the advice, diagnosis or treatment of a health care professional.
          </Text>
          <Text style={styles.paragraph}>
            The responses you submit to the on-line questionnaire are then
            processed by an algorithm that will issue a recommendation regarding
            the need for you to consult a mental health professional in a timely
            manner.
          </Text>
          <Text style={styles.paragraph}>
            The algorithm is based on the clinical knowledge of a number of
            experts who participated in the design of the refer-O-scope as well
            as on a thorough review of the scientific literature and the results
            of focus group discussions.
          </Text>
          <Text style={styles.paragraph}>
            To help you explain and discuss the situation with a health care
            professional, the more pertinent observations have been provided
          </Text>
          <Text style={styles.paragraph}>
            Learn more about psychotic disorders : www.aqppep.com
          </Text>
          <Text style={styles.paragraphBold}>
            I am concerned about the mental health of a loved one. Here are my
            observations and the follow-up recommendation.
          </Text>

          <View style={{ ...styles.recommandation, borderColor: recColor }}>
            <Text style={{ ...styles.recommandationTitle, color: recColor }}>
              {recTitle}
            </Text>
            <Text>{recText}</Text>
          </View>

          <View>
            <Text style={styles.paragraphResourcesTitle}>
              Contacting the right resources
            </Text>
            <Text style={styles.paragraphResources}>
              1. A doctor or health care professional
            </Text>
            <Text style={styles.paragraphResources}>
              2. The health and social services centre (CSSS) in your region or
              your closest local community service centre (CLSC).
            </Text>
            <Text style={styles.paragraphResources}>
              3. HEALTH INFORMATION LINE 811
            </Text>
            <Text style={styles.paragraphResources}>
              4. Your regional family support organization{" "}
              <Link src="https://www.avantdecraquer.com/">
                www.avantdecraquer.com/
              </Link>
            </Text>
            <Text style={styles.paragraphResources}>
              5. The Student Health Services in your institution
            </Text>
            <Text style={styles.paragraphResources}>
              6. La Société québécoise de la schizophrénie et des psychoses
              apparentées
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone. : 1 866 888-2323 (toll free)
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone. : 514 251-4125
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link src="mailto:info@schizophrenie.qc.ca">
                info@schizophrenie.qc.ca
              </Link>
            </Text>
            <Text style={styles.paragraphResources}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link src="https://www.schizophrenie.qc.ca/">
                www.schizophrenie.qc.ca/fr/
              </Link>
            </Text>
          </View>
        </View>

        <BasDePage isFr={isFr} />
      </Page>

      <Page size="LETTER" style={styles.page}>
        <View style={styles.content}>
          <Text>
            The nature of your relationship with the person this is about :{" "}
            {values["1.1.personne_repondante"]}
          </Text>
          <Text>
            Gender of the person you are concerned about : {values["1.2.sexe"]}
          </Text>
          <Text>Current age : {values["1.2.age"]}</Text>
        </View>

        <View style={{ padding: "0px 30px" }}>
          <View style={styles.resultLine}>
            <Text style={styles.resultText}></Text>
            <View style={styles.resultCheckbox}>
              <Text>Never</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Occasionally</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Often</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Very often</Text>
            </View>
            <View style={styles.resultCheckbox}>
              <Text>Do not know</Text>
            </View>
          </View>
          {Object.keys(values)
            .filter(k => k.startsWith("2"))
            .map(key => {
              return (
                <View key={key} style={styles.resultLine}>
                  <Text style={styles.resultText}>{detailed[key].label}</Text>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "0" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "1" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "2" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {detailed[key].value === "3" && <Text>x</Text>}
                  </View>
                  <View style={styles.resultCheckbox}>
                    {(detailed[key].value === "-1" ||
                      detailed[key].value === "4") && <Text>x</Text>}
                  </View>
                </View>
              )
            })}
        </View>

        <BasDePage />
      </Page>
    </Document>
  )
}
